import { render, staticRenderFns } from "./DedicatedSeoDashboard.vue?vue&type=template&id=1ec99405&scoped=true&"
import script from "./DedicatedSeoDashboard.vue?vue&type=script&lang=js&"
export * from "./DedicatedSeoDashboard.vue?vue&type=script&lang=js&"
import style0 from "./DedicatedSeoDashboard.vue?vue&type=style&index=0&lang=css&"
import style1 from "../assets/less/dedicated-seo-dashboard.less?vue&type=style&index=1&id=1ec99405&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ec99405",
  null
  
)

export default component.exports