<style lang="css">
    @font-face {
        font-family: "Segoe UI";
        src: url(//semalt.com/css/fonts/SegoeUI.ttf);
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "Segoe UI";
        src: url(//semalt.com/css/fonts/SegoeUI-Light.ttf);
        font-weight: 100;
        font-style: normal;
    }
    @font-face {
        font-family: "Segoe UI";
        src: url(//semalt.com/css/fonts/SegoeUI-SemiBold.ttf);
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: "Segoe UI";
        src: url(//semalt.com/css/fonts/SegoeUI-Bold.ttf);
        font-weight: 700;
        font-style: normal;
    }
    .btree a.linkres{
        color: #0288D1;
        text-decoration: underline;
    }
    sup{
        color: #0288D1;
        font-weight: bold;
    }
    a{
        color: #0288D1;
    }
    .flex-row h4 span{
        color: #a5a5a5;
        font-weight: normal;
    }
    td.tableHead span{
        color: #b5b5b5;
        font-weight: normal;
    }
    td.tableHead.tdMain span{
        color: #42C9A8;
    }
    .DedicatedTitle{
        margin-top: 55px;
    }
    .underline {
        text-decoration: underline;
        padding: 0 1px;
        color: #818f99!important;
        text-align: center;
    }
    html {
        scroll-behavior: smooth;
    }
    .landscape .mobile-menu{
        top: 72px!important;
    }

    .lang_es #nav_page:hover {
        width: 332px!important;
    }
    .lang_fr #nav_page:hover {
        width: 314px!important;
    }
    .lang_pt #nav_page:hover {
        width: 288px!important;
    }
    .lang_it #nav_page:hover {
        width: 290px!important;
    }
    .lang_tr #nav_page:hover {
        width: 296px!important;
    }
    .lang_vi #nav_page:hover {
        width: 340px!important;
    }
    .df-video h2 span{
        color: #3598db;
    }
</style>
<template>
    <div>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <div v-if="ll" id="nav_page" :class="[mobileMenu?'mopen':'']">
            <i class="fas fa-times" @click="mobileMenu=false"></i>
            <div class="page_navigation">{{ll.i7423}}</div>
            <a @click="aname('howitworks')" class="navrow"><i class="icon inav-01"></i><span>{{ll.i7418}}</span></a>
            <a @click="aname('examples')" class="navrow"><i class="icon inav-02"></i><span>{{ll.i7419}}</span></a>
            <a @click="aname('tools')" class="navrow"><i class="icon inav-03"></i><span>{{ll.i7420}}</span></a>
            <a @click="aname('comparison')" class="navrow"><i class="icon inav-04"></i><span>{{ll.i7421}}</span></a>
            <a @click="aname('pricing')" class="navrow"><i class="icon inav-05"></i><span>{{ll.i7422}}</span></a>
        </div>
        <div class="mobile-menu" @click="mobileMenu=true"><i></i><i class="t"></i><i></i></div>
        <DedicatedTitle></DedicatedTitle>
        <div v-if="ll" class="df-video">
            <h2 data-vl="7412" v-html="ll.i7412"></h2>
            <div class="df-video-content" >
                <img v-if="!videoVimeo" @click="videoVimeo=true" class="play_video_vimeo" src="/img/dedicated/play_video_2.svg" />
                <template v-if="!videoVimeo">
                    <img class="vimeo_video" src="/img/dedicated/video72.png" />
                </template>
                <template v-else>
                    <iframe :src="'https://player.vimeo.com/video/505282164?autoplay=1'" :width="video.width" :height="video.height" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </template>
            </div>
        </div>
        <div v-if="ll" class="decontent">
            <div class="dblock-bg2"></div>
            <div class="dblock dblock-label">
                <h2 class="h2btn" data-vl="7243">{{ll.i7243}}</h2>
                <div class="btree">
                    <div class="romb"></div>
                    <h4 data-vl="7244">{{ll.i7244}}</h4>
                    <p data-vl="7245" v-html="ll.i7245"></p>
                </div>
                <div class="btree">
                    <div class="romb"></div>
                    <h4 data-vl="7246">{{ll.i7246}}</h4>
                    <p data-vl="7247" v-html="ll.i7247"></p>
                </div>
                <div class="btree last-child">
                    <div class="romb"></div>
                    <h4 data-vl="7248">{{ll.i7248}}</h4>
                    <p v-html="ll.i7249.replace('%s','<a class=\'linkres\' href=\'https://semalt.com/reseller-program\' target=\'_blank\'>').replace('%s','</a>')"></p>
                </div>
                <div class="clear"></div>
            </div>



            <div class="dblock dblock100">
                <h2 data-vl="7260">{{ll.i7260}}</h2>
                <div class="description" data-vl="7261" v-html="ll.i7261"></div>
            </div>
            <div class="dblock dblock-rev">
                <div class="img-l"><img src="/img/dedicated/img-10-1.png" srcset="/img/dedicated/img-10-2.png 2x" /></div>
                <div class="dright">
                    <h3 data-vl="7262">{{ll.i7262}}</h3>
                    <p class="ptext" data-vl="7263" v-html="ll.i7263"></p>
                </div>
            </div>
            <div class="dblock">
                <div class="dleft">
                    <h3 data-vl="7264">{{ll.i7264}}</h3>
                    <p class="ptext" data-vl="7265" v-html="ll.i7265"></p>
                </div>
                <div class="img-r"><img src="/img/dedicated/img-11-1.png" srcset="/img/dedicated/img-11-2.png 2x" /></div>
            </div>
            <div class="dblock dblock-rev">
                <div class="img-l"><img src="/img/dedicated/img-12-1.png" srcset="/img/dedicated/img-12-2.png 2x" /></div>
                <div class="dright">
                    <h3 data-vl="7266">{{ll.i7266}}</h3>
                    <p class="ptext" data-vl="7267" v-html="ll.i7267"></p>
                </div>
            </div>

            <div class="rectangle-m">
                <div class="rectangle">
                    <div class="r-left">
                        <h3 data-vl="7268" v-html="ll.i7268"></h3>
                        <p class="ptext" data-vl="7269" v-html="ll.i7269"></p>
                    </div>
                    <a target="_blank" href="https://demo.semalt.com/" class="green-button kor" data-vl="7270">{{ll.i7270}}</a>
                    <div class="clear"></div>
                </div>
            </div>
            <div class="dblock dblock1100">
                <div class="aname" ref="howitworks"></div>
                <h2 data-vl="7271">{{ll.i7271}}</h2>
                <div class="b-flex">
                    <div class="row">
                        <p data-vl="7272"><span>1.</span>{{ll.i7272}}</p>
                    </div>
                    <div class="row">
                        <img src="/img/dedicated/img-13-1.png" srcset="/img/dedicated/img-13-2.png 2x" />
                    </div>
                    <div class="row">
                        <img src="/img/dedicated/img-14-1.png" srcset="/img/dedicated/img-14-2.png 2x" />
                    </div>
                    <div class="row">
                        <p data-vl="7273"><span>2.</span>{{ll.i7273}}</p>
                    </div>
                    <div class="row">
                        <p data-vl="7274"><span>3.</span>{{ll.i7274}}</p>
                    </div>
                    <div class="row">
                        <img src="/img/dedicated/img-15-1.png" srcset="/img/dedicated/img-15-2.png 2x" />
                    </div>
                </div>
            </div>

            <div class="rectangle-m-2">
                <div class="rectangle rectangle-border">
                    <div class="r-center">
                        <h3 data-vl="7275" v-html="ll.i7275"></h3>
                        <p class="ptext" data-vl="7276" v-html="ll.i7276"></p>
                    </div>
                    <div class="center">
                        <div class="green-button mb20" @click="aname('pricing')" data-vl="7277">{{ll.i7277}}</div>
                    </div>
                </div>
            </div>

            <div class="dblock ">
                <div class="b-flex">
                    <div class="row">
                        <img src="/img/dedicated/img-16-1.png" srcset="/img/dedicated/img-16-2.png 2x" />
                    </div>
                    <div class="row">
                        <p data-vl="7278"><span>4.</span>{{ll.i7278}}</p>
                    </div>
                    <div class="row">
                        <p data-vl="7279"><span>5.</span>{{ll.i7279}}</p>
                    </div>
                    <div class="row">
                        <img src="/img/dedicated/img-17-1.png" srcset="/img/dedicated/img-17-2.png 2x" />
                    </div>
                </div>
            </div>


            <div class="dblock blaunched">
                <div class="aname" ref="examples"></div>
                <h2 class="h2btn" data-vl="7250">{{ll.i7250}}</h2>
                <div v-for="(item, index) in reseller" :key="index" class="breseller">
                    <img class="clflag" :alt="item.country.toUpperCase()" :src="'/img/flags3/iso/flat/32/'+ item.country +'.png'" />
                    <img class="rlogo" :src="item.logo" />
                    <a :href="item.site" target="_blank" rel="nofollow">{{item.site}}</a>
                </div>
                <div class="clear"></div>
            </div>

            <div class="dblock dblock100">
                <h2 data-vl="7280">{{ll.i7280}}</h2>
                <div class="description" data-vl="7351" v-html="ll.i7351"></div>
            </div>

            <div class="rectangle-m-3">
                <div class="rectangle2">
                    <div class="r-left">
                        <h3 data-vl="7352" v-html="ll.i7352"></h3>
                        <ul>
                            <li v-for="(item, index) in ll.i7353.split('%li')" :key="index"><template v-if="item.trim()"><i class="rd"><i></i></i><span v-html="item.trim()"></span></template></li>
                        </ul>
                    </div>
                    <div class="center">
                        <a class="green-button" target="_blank" href="https://semalt.com/dsd-wizard" data-vl="7354">{{ll.i7354}}</a>
                    </div>
                </div>
            </div>


            <div class="dblock dblock80">
                <h2 data-vl="7355">{{ll.i7355}}</h2>
                <div class="btree-flex">
                    <div class="row">
                        <h3 data-vl="7356"><i class="icon icon18"></i>{{ll.i7356}}</h3>
                        <p class="desc" data-vl="7357" v-html="ll.i7357"></p>
                    </div>
                    <div class="row">
                        <h3 data-vl="7358"><i class="icon icon19"></i>{{ll.i7358}}</h3>
                        <p class="desc" data-vl="7359" v-html="ll.i7359"></p>
                    </div>
                    <div class="row">
                        <h3 data-vl="7360"><i class="icon icon20"></i>{{ll.i7360}}</h3>
                        <p class="desc" data-vl="7361" v-html="ll.i7361"></p>
                    </div>
                </div>
                <p class="footnote" v-html="ll.i7362"></p>
                <div class="center mt30">
                    <a class="green-button" target="_blank" href="https://semalt.com/dsd-wizard" data-vl="7354">{{ll.i7354}}</a>
                </div>
            </div>


            <div class="rectangle4">
                <div class="r-center">
                    <h2 data-vl="7364" v-html="ll.i7364"></h2>
                    <ul>
                        <li><i class="rd"><i></i></i><span v-html="ll.i7365"></span></li>
                        <li><i class="rd"><i></i></i><span v-html="ll.i7366"></span></li>
                    </ul>
                    <div class="p-bottom">{{ll.i7367}}</div>
                </div>
            </div>


            <div class="dblock">
                <div class="aname" ref="tools"></div>
                <h2 data-vl="7368">{{ll.i7368}}</h2>
            </div>
            <div class="b-reports">

                <div class="row row-text row-text-l">
                    <h3 data-vl="7233">{{ll.i7233}}</h3>
                    <ul class="rblue">
                        <li v-for="(item, index) in ll.i7234.split('%li')" :key="index"><template v-if="item.trim()"><i class="rd"><i></i></i>{{ item.trim() }}</template></li>
                    </ul>
                </div>
                <div class="row row-img">
                    <div class="img-r"><img src="/img/dedicated/img-21-1.png" srcset="/img/dedicated/img-21-2.png 2x"></div>
                </div>

                <div class="row row-img">
                    <div class="img-l"><img src="/img/dedicated/img-22-1.png" srcset="/img/dedicated/img-22-2.png 2x"></div>
                </div>
                <div class="row row-text">
                    <h3 data-vl="7235">{{ll.i7235}}</h3>
                    <ul class="rblue">
                        <li v-for="(item, index) in ll.i7236.split('%li')" :key="index"><template v-if="item.trim()"><i class="rd"><i></i></i>{{ item.trim() }}</template></li>
                    </ul>
                </div>

                <div class="row row-text row-text-l">
                    <h3 data-vl="7237">{{ll.i7237}}</h3>
                    <ul class="rblue">
                        <li v-for="(item, index) in ll.i7238.split('%li')" :key="index"><template v-if="item.trim()"><i class="rd"><i></i></i>{{ item.trim() }}</template></li>
                    </ul>
                </div>
                <div class="row row-img">
                    <div class="img-r"><img src="/img/dedicated/img-23-1.png" srcset="/img/dedicated/img-23-2.png 2x" /></div>
                </div>

                <div class="row row-img">
                    <div class="img-l"><img src="/img/dedicated/img-24-1.png" srcset="/img/dedicated/img-24-2.png 2x" /></div>
                </div>
                <div class="row row-text">
                    <h3 data-vl="7239">{{ll.i7239}}</h3>
                    <ul class="rblue">
                        <li v-for="(item, index) in ll.i7240.split('%li')" :key="index"><template v-if="item.trim()"><i class="rd"><i></i></i>{{ item.trim() }}</template></li>
                    </ul>
                </div>

                <div class="row row-text row-text-l">
                    <h3 data-vl="7241">{{ll.i7241}}</h3>
                    <ul class="rblue">
                        <li v-for="(item, index) in ll.i7242.split('%li')" :key="index"><template v-if="item.trim()"><i class="rd"><i></i></i>{{ item.trim() }}</template></li>
                    </ul>
                </div>
                <div class="row row-img">
                    <div class="img-r"><img src="/img/dedicated/img-25-1.png" srcset="/img/dedicated/img-25-2.png 2x" /></div>
                </div>
            </div>

            <div class="dblock dblock1100">
                <div class="aname" ref="comparison"></div>
                <h2 data-vl="7370">{{ll.i7370}}</h2>
                <div class="divText">
                    <table class="tableText">
                        <tr v-for="(row, index) in tableText" :key="index" >
                            <td v-for="(rowTd, index2) in row" :key="index2" :class="[index==0?'tableHead':'', index2==0?'tdFirst':'', index2==1?'tdMain':'',index2==1&&index==(tableText.length-1)?'mainEnd':'']" v-html="tFilter(rowTd)"></td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="blue-round">
                <div class="aname pricing" ref="pricing"></div>
                <div class="b-content">
                    <h3 data-vl="7371">{{ll.i7371}}</h3>
                    <div class="desc" v-html="ll.i7372"></div>
                    <div class="center">
                        <a href="https://semalt.com/dsd-wizard" target="_blank" class="button-white" data-vl="7354">{{ll.i7354}}</a>
                    </div>
                </div>
            </div>

            <div class="dblock">
                <h2 data-vl="7374">{{ll.i7374}}</h2>
                <div class="description" data-vl="7375" v-html="ll.i7375"></div>
            </div>
            <div class="flex-block">
                <!--
                <div class="flex-row">
                    <div class="flex-title orange" data-vl="7376">{{ll.i7376}}</div>
                    <h4 data-vl="7377">{{ll.i7377}}</h4>
                    <p data-vl="7378">{{ll.i7378}}</p>
                    <h4 data-vl="7379">{{ll.i7379}}</h4>
                    <p data-vl="7380">{{ll.i7380}}</p>
                    <h4 data-vl="7381">{{ll.i7381}}</h4>
                    <p data-vl="7382">{{ll.i7382}}</p>
                    <h4 data-vl="7383">{{ll.i7383}}</h4>
                    <p data-vl="7384">{{ll.i7384}}</p>
                    <h4 data-vl="7385">{{ll.i7385}}</h4>
                    <p data-vl="7386">{{ll.i7386}}</p>
                    <h4 data-vl="7387" v-html="ll.i7387"></h4>
                    <p data-vl="7388">{{ll.i7388}}</p>
                    <h4 class="blue" data-vl="7389">{{ll.i7389}}</h4>
                    <p data-vl="7390">{{ll.i7390}}</p>

                    <div class="center">
                        <a href="https://semalt.com/dsd-wizard" target="_blank" class="button-price orange" data-vl="7391">{{ll.i7391}}</a>
                    </div>
                </div>


                <div class="flex-row">
                    <div class="flex-title" data-vl="7392">{{ll.i7392}}</div>
                    <h4 data-vl="7393">{{ll.i7393}}</h4>
                    <p data-vl="7394">{{ll.i7394}}</p>
                    <h4 data-vl="7395">{{ll.i7395}}</h4>
                    <p data-vl="7396">{{ll.i7396}}</p>
                    <h4 data-vl="7397">{{ll.i7397}}</h4>
                    <p data-vl="7398">{{ll.i7398}}</p>
                    <h4 data-vl="7399">{{ll.i7399}}</h4>
                    <p data-vl="7400">{{ll.i7400}}</p>
                    <h4 data-vl="7401">{{ll.i7401}}</h4>
                    <p data-vl="7402">{{ll.i7402}}</p>
                    <h4 data-vl="7403">{{ll.i7403}}</h4>
                    <p data-vl="7404">{{ll.i7404}}</p>

                    <div class="center">
                        <div class="button-price button-request">{{ll.i7405}}</div>
                    </div>
                </div>
                <div class="flex-row">
                    <div class="flex-title green" data-vl="7406">{{ll.i7406}}</div>
                    <h4 data-vl="7407">{{ll.i7407}}</h4>
                    <p data-vl="7408">{{ll.i7408}}</p>
                    <h4 data-vl="7409">{{ll.i7409}}</h4>
                    <p data-vl="7410">{{ll.i7410}}</p>

                    <div class="center">
                        <div class="button-price green button-request">{{ll.i7411}}</div>
                    </div>
                </div>
                -->

                <div class="flex-row">
                    <div class="flex-row-top">
                        <div class="flex-title">
                            <span data-vl="7424">{{ll.i7424}}</span>
                            <span class="litle" data-vl="7425">{{ll.i7425}}</span>
                        </div>
                        <h4 data-vl="7401">{{ll.i7401}}</h4>
                        <p data-vl="7402">{{ll.i7402}}</p>
                        <h4 data-vl="7381">{{ll.i7381}}</h4>
                        <p data-vl="7382">{{ll.i7382}}</p>
                        <h4 data-vl="7395">{{ll.i7395}}</h4>
                        <p data-vl="7396">{{ll.i7396}}</p>
                        <h4 data-vl="7428">{{ll.i7428}}</h4>
                        <p data-vl="7429">{{ll.i7429}}</p>
                    </div>
                    <div class="flex-row-bottom center">
                        <div class="button-price button-request">{{ll.i7411}}</div>
                    </div>
                </div>
                <div class="flex-row">
                    <div class="flex-row-top">
                        <div class="flex-title green">
                            <span data-vl="7426">{{ll.i7426}}</span>
                            <span class="litle" data-vl="7427">{{ll.i7427}}</span>
                        </div>

                        <h4 data-vl="7430">{{ll.i7430}}<span class="abs" data-vl="7432">{{ll.i7432}}</span></h4>
                        <p data-vl="7431">{{ll.i7431}}</p>

                        <h4 data-vl="7433">{{ll.i7433}}<span class="abs" data-vl="7435">{{ll.i7435}}</span></h4>
                        <p data-vl="7434">{{ll.i7434}}</p>

                        <h4 data-vl="7436">{{ll.i7436}}<span class="abs" data-vl="7438">{{ll.i7438}}</span></h4>
                        <p data-vl="7437">{{ll.i7437}}</p>
                    </div>
                    <div class="flex-row-bottom center">
                        <div class="button-price green button-request">{{ll.i7411}}</div>
                    </div>
                </div>
            </div>
        </div>

        <MoreQuestions></MoreQuestions>
    </div>
</template>

<script>
    /* eslint-disable */
    import DedicatedTitle from '../components/block/DedicatedTitle';
    import MoreQuestions from '../components/block/MoreQuestions';
    import axios from 'axios'

    export default {
        name: "DedicatedSeoDashboard",
        components: {
            DedicatedTitle,
            MoreQuestions
        },
        computed:{
            lang(){
                return this.$root.lang;
            }
        },
        created() {
            window.addEventListener("resize", this.resizeEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeEventHandler);
        },
        data: function() {
            return {
                ll: null,
                reseller:[],
                manager:null,
                tableText:[],
                hashs:['howitworks','examples','tools','comparison','pricing'],
                mobileMenu:false,
                videoVimeo: false,
                video:{
                    width:0,
                    height:'auto',
                    defaultWidth: 864,
                    defaultHeight: 487,
                    lastWidth: 0
                },
            }
        },
        watch:{
            lang(to, from){
                this.getll();
            }
        },
        methods: {
            async init(){
                this.route();
                await this.getll();
                await this.main();
                this.resizeEventHandler();
                this.routeHash();
            },
            async getll(){
                let res = await axios.post('/api/main/get', {datall:{"lang": this.lang,"controller": ["dedicated-semalt-dashboard"]} });
                if(res && res.data && res.data.datall) {
                    this.ll = res.data.datall;
                    this.createTableText();
                }
            },
            async main(){
                let res = await axios.post('/api/main/get', {controller:[{name:"dedicated"}, {name:"manager"}] });
                if(res && res.data ) {
                    if(res.data.dedicated){
                        this.reseller = res.data.dedicated;
                    }
                    if(res.data.manager){
                        this.manager = res.data.manager;
                    }
                }
            },
            route(){
                if(this.$route.params.lang){
                    if(this.$root.languages.includes(this.$route.params.lang)){
                        if(this.$route.params.lang != this.$root.lang) {
                            this.$root.lang = this.$route.params.lang;
                        }
                    }
                    else{
                        this.$router.push('/en/dedicated-seo-dashboard');
                        this.$router.push('/dedicated-seo-dashboard');
                        this.$root.lang = 'en';
                    }
                }
            },
            routeHash(){
                if(location.hash && this.hashs.includes(location.hash.replace('#',''))){
                    this.$nextTick(()=>{
                        setTimeout(()=>{this.aname(location.hash.replace('#',''), false);},600);
                    });
                }
            },
            createTableText(){
                let aa = [], res = [];
                aa[0] = this.ll.i7413.split("\n");
                aa[1] = this.ll.i7414.split("\n");
                aa[2] = this.ll.i7415.split("\n");
                aa[3] = this.ll.i7416.split("\n");
                aa[4] = this.ll.i7417.split("\n");
                aa[0].unshift("");
                for(let jj in aa[0]){
                    if(jj==1) continue;
                    res.push([aa[0][jj], aa[1][jj], aa[2][jj], aa[3][jj], aa[4][jj]]);
                }
                this.tableText = res;
            },
            tFilter(val){
                if(val){
                    return val.replace('%ok','<img src="/img/dedicated/ok.svg" />').replace('%not','<img src="/img/dedicated/not.svg" />');
                } //val.replace('%ok','<i class="fas fa-check"></i>').replace('%not','<i class="fas fa-times"></i>');
                return '';
            },
            aname(val, is_push=true){
                if(this.$route.hash && this.$route.hash.replace('#','')==val ) is_push = false;
                let slide = this.$refs[val];
                if(is_push) this.$router.push({ hash: '#'+val });
                let top = window.scrollY + slide.getBoundingClientRect().y;
                window.scrollTo(0, top);

                if(this.mobileMenu){
                    setTimeout(()=>{this.mobileMenu=false;},100);
                }

            },
            tblank(val){
                return val.replace(/<a /g, '<a target="_blank" ' );
            },
            resizeEventHandler(val){

                this.video.width = this.video.defaultWidth;
                this.video.height = this.video.defaultHeight;

                if(val && val.currentTarget && val.currentTarget.innerWidth){
                    if(val.currentTarget.innerWidth<this.video.defaultWidth){
                        this.video.width = val.currentTarget.innerWidth - 30;
                        this.video.height = parseInt(this.video.width * this.video.defaultHeight / this.video.defaultWidth);
                    }
                }
                else if(window.outerWidth){
                    if(window.outerWidth<this.video.defaultWidth){
                        this.video.width = window.outerWidth -30;
                        this.video.height = parseInt(this.video.width * this.video.defaultHeight / this.video.defaultWidth);
                    }
                }
                if(this.video.width !=this.video.lastWidth ) {
                    this.videoVimeo = false;
                    this.video.lastWidth = this.video.width;
                }
            }
        },
        mounted(){
            this.init();
        }
    }
</script>
<style src="../assets/less/dedicated-seo-dashboard.less" lang="less" scoped></style>